import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import ImageModule from "../components/image-module"
import SEO from "../components/seo"
import GradientBackground from "../components/gradient-background"

function About() {
  const {
    markdownRemark: { frontmatter },
  } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          title
          image {
            childImageSharp {
              fixed(width: 1500) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  const image = frontmatter.image.childImageSharp.fixed.src

  return (
    <>
      <SEO title="Home" />
      <GradientBackground
        gradientValues={[
          "rgba(248, 93, 7, 1) 0%",
          "rgba(194, 61, 61, 1) 57%",
          "rgba(143, 112, 112, 1) 100%",
        ]}
      />
      <ImageModule
        style={{
          background: `url(${image})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
    </>
  )
}

export default About
