import styled from "styled-components"

const ImageModule = styled.div`
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 100;
  transition: 0.25s background ease-out;
`

export default ImageModule
